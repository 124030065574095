import { Button, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { default as CloudV1Icon } from '@/assets/errorPage/cloud_icon_v1.svg';
import { default as CloudV2Icon } from '@/assets/errorPage/cloud_icon_v2.svg';
import { default as WarningIcon } from '@/assets/errorPage/warning_sign_icon.svg';
import { useRouteError } from 'react-router-dom';

interface ErrorBoundaryProps {
  trackLinkError?: boolean;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ trackLinkError }: ErrorBoundaryProps) => {
  const { t } = useTranslation();
  const error = useRouteError() as Error;

  const isLocal = import.meta.env.VITE_ENV_MODE === 'local';

  return (
    <Grid direction="column" sx={{ m: 5, bgcolor: 'white' }}>
      {/* Cloud Icon at the Top */}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', pl: '8%' }}>
        <img src={CloudV1Icon} alt={t('cloudIconAlt')} />
      </Grid>

      <Stack spacing={2} direction="row" sx={{ bgcolor: 'white', p: 4 }}>
        {/* Warning Icon */}
        <Grid item xs={3} sx={{ textAlign: 'center' }}>
          <img src={WarningIcon} alt={t('warningIconAlt')} style={{ width: '200px' }} />
        </Grid>

        {/* Text Content */}
        <Grid item xs={9} px={5}>
          {trackLinkError === true ? (
            <Stack spacing={2} sx={{ bgcolor: 'white', p: 4 }} gap={5}>
              <Typography variant="h1" sx={{ fontWeight: 'bold', fontSize: '24px' }}>
                {t('trackLinkErrorTitle')}
              </Typography>
              <Typography variant="body1" sx={{ py: 3, fontSize: '18px' }}>
                {t('trackLinkErrorText')}
              </Typography>
              <Button
                variant="outlined"
                onClick={() => window.location.reload()}
                sx={{
                  mt: 2,
                  width: 'fit-content',
                  bgcolor: '#002E6D',
                  color: 'white',
                  ' &:hover': {
                    bgcolor: '#002E6D'
                  }
                }}
              >
                {t('reloadButtonText')}
              </Button>
            </Stack>
          ) : isLocal ? (
            error && (
              <Stack spacing={2} sx={{ bgcolor: 'white', p: 4 }} gap={5}>
                <Typography> {error.name}</Typography>
                <Typography> {error.message}</Typography>
                <Typography> {error.stack}</Typography>
              </Stack>
            )
          ) : (
            <Stack spacing={2} sx={{ bgcolor: 'white', p: 4 }} gap={5}>
              <Typography variant="h1" sx={{ fontWeight: 'bold', fontSize: '24px' }}>
                {t('pageUnavailableTitle')}
              </Typography>
              <Typography variant="body1" sx={{ py: 3, fontSize: '18px' }}>
                {t('returnToHomeText')}{' '}
                <a href="/" style={{ textDecoration: 'underline', fontWeight: 'bold', color: '#002E6D' }}>
                  {t('returnLinkText')}
                </a>{' '}
                {t('reloadPageText')}
              </Typography>

              <Button
                variant="outlined"
                onClick={() => (window.location.href = '/')}
                sx={{
                  mt: 2,
                  width: 'fit-content',
                  bgcolor: '#002E6D',
                  color: 'white',
                  ' &:hover': {
                    bgcolor: '#002E6D'
                  }
                }}
              >
                {t('reloadButtonText')}
              </Button>
            </Stack>
          )}
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', pr: '10%' }}>
            <img src={CloudV2Icon} alt={t('cloudIconAlt')} />
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
};

export default ErrorBoundary;
